<footer [appStoryblok]="_editable">
  <div class="container-xl pb-4 d-flex flex-column">
    <div class="row pt-4">
      <div
        class="col-6 col-lg-2 order-1 order-lg-0"
        *ngFor="let blok of theme?.content?.footer_menu; let first = first"
      >
        <ndc-dynamic
          *ngIf="components"
          [ndcDynamicComponent]="components[blok.component]"
          [ndcDynamicInputs]="blok"
        >
        </ndc-dynamic>
      </div>

      <div class="col-12 col-lg-4 offset-lg-1 order-0 order-lg-3 ms-lg-auto">
        <app-email-form [theme]="theme"></app-email-form>
      </div>
    </div>
    <div class="row pt-5 mt-auto">
      <div class="col-12 d-flex flex-row justify-content-between">
        <span class="socials d-flex align-items-center">
          <a
            [storyblokLink]="theme?.content?.facebook"
            class="actionable"
            alt="fressko facebook"
            *ngIf="theme?.content?.facebook"
          >
            <img src="/assets/facebook.svg" lazy />
          </a>
          <a
            [storyblokLink]="theme?.content?.instagram"
            class="actionable"
            alt="fressko instagram"
            *ngIf="theme?.content?.instagram"
          >
            <img src="/assets/instagram.svg" lazy />
          </a>
          <a
            [storyblokLink]="theme?.content?.tiktok"
            class="actionable"
            alt="fressko tiktok"
            *ngIf="theme?.content?.tiktok"
          >
            <img src="/assets/tiktok.svg" lazy />
          </a>
          <a
            [storyblokLink]="theme?.content?.linkedin"
            class="actionable"
            alt="fressko linkedin"
            *ngIf="theme?.content?.linkedin"
          >
            <img src="/assets/linkedin.svg" lazy />
          </a>
        </span>
        <app-store-selector-toggle></app-store-selector-toggle>
      </div>
    </div>
    <div class="row pt-5">
      <div class="col-lg-4 col-12 order-lg-0 order-1">
        <span class="small">
          © {{ date | date : "YYYY" }}, Made by Fressko. All right reserved.
        </span>
      </div>

      <div
        class="col-12 col-lg-8 justify-content-lg-end justify-content-start d-flex order-0 order-lg-1 mb-2 mb-lg-0"
      >
        <a
          class="small text-decoration-none me-3"
          [routerLink]="['/pages/privacy-policy']"
        >
          Privacy Policy
        </a>
        <a
          class="small text-decoration-none me-3"
          [routerLink]="['/pages/terms-conditions']"
        >
          Terms & Conditions
        </a>
        <a
          name="Ecommerce Site Designed & Built by Plutocracy"
          class="d-none d-lg-inline small text-decoration-none"
          href="https://plutocracy.io"
        >
          Made by. Plutocracy Customs
        </a>
      </div>
    </div>
  </div>
</footer>
