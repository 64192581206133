<div class="content" [ngClass]="[_class]" [appStoryblok]="_editable">
  <ng-container *ngIf="story.content">
    <app-ribbon
      class="z-3"
      *ngIf="_showribbon"
      [appStoryblok]="_editable"
      [theme]="story"
    ></app-ribbon>
    <app-header
      [ngClass]="[_class]"
      class="z-3"
      [appStoryblok]="_editable"
      [theme]="story"
    ></app-header>
  </ng-container>
  <router-outlet *ngIf="!_notFound"></router-outlet>
  <router-outlet name="error"></router-outlet>
</div>
<app-footer
  *ngIf="_class !== 'personaliser'"
  [ngClass]="[_class]"
  class="z-3"
  [appStoryblok]="_editable"
  [theme]="story"
></app-footer>
@defer {
<app-minicart [theme]="story"></app-minicart>
<app-modal [theme]="story"></app-modal>
<app-toast></app-toast>
} @placeholder {
<div></div>
}
