import { DOCUMENT, NgClass, NgIf } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  Input,
  OnInit,
  Renderer2,
  afterNextRender,
} from '@angular/core';
import {
  NavigationEnd,
  NavigationError,
  Router,
  RouterOutlet,
} from '@angular/router';
import { filter, map } from 'rxjs';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { MinicartComponent } from './components/minicart/minicart.component';
import { ModalComponent } from './components/modal/modal.component';
import { RibbonComponent } from './components/ribbon/ribbon.component';
import { ToastComponent } from './components/toast/toast.component';
import { StoryblokDirective } from './directive/storyblok.directive';
import { AnalyticsService } from './service/analytics.service';
import { EnvironmentService } from './service/environment.service';
import { SEOService } from './service/seo.service';
import { StoryBlokService } from './service/storyblok.service';
declare global {
  interface Window {
    storyblok: any;
  }
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [
        NgIf,
        NgClass,
        RibbonComponent,
        StoryblokDirective,
        HeaderComponent,
        RouterOutlet,
        FooterComponent,
        MinicartComponent,
        ModalComponent,
        ToastComponent,
    ]
})
export class AppComponent implements OnInit {
  story: { content: any; name: string } | any = { content: null, name: '' };
  @Input() _editable!: string;
  components: any;
  _showribbon: boolean = false;
  _class: string = '';
  _notFound: boolean = false;
  hasRun: boolean = false;
  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private storyBlokService: StoryBlokService,
    private seoService: SEOService,
    private analyticsService: AnalyticsService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private renderer: Renderer2,
    private environmentService: EnvironmentService
  ) {
    this.seoService.init();
    this.analyticsService.init();
    this.environmentService.init();
    router.events
      .pipe(
        filter((evt) => evt instanceof NavigationError),
        map((evt) => evt as NavigationError)
      )
      .subscribe((evt) => {
        if (evt.error instanceof Error && evt.error.name == 'ChunkLoadError') {
          window.location.assign(evt.url);
        }
      });
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((value: any) => {
        if (value instanceof NavigationEnd) {
          const currentUrl = value.url.split('?')[0];

          if (currentUrl.includes('personaliser-product/')) {
            this._class = 'personaliser';
          } else if (currentUrl.includes('products/gift-card/')) {
            this._class = 'giftcard';
          } else {
            this._class = '';
          }
          this._notFound = value.url.includes('(error:404)') ? true : false;

          const url = structuredClone(value.url.split('?')[0]);
          this._showribbon = url === '/' || false;
          // ['products', 'collections'].includes(url.split('/')[1]);

          this.seoService.setHreflangTags(this.renderer);
        }
      });
    afterNextRender(() => {
      try {
        if (window?.storyblok) {
          window.storyblok.init();
          window.storyblok.on(['change', 'published'], function () {
            location.reload();
          });
          window.storyblok.on('input', (event: any) => {
            if (
              this.story &&
              event.story.content._uid === this.story?.content?._uid
            ) {
              this.story = event.story;
              this.cd.markForCheck();
            }
          });
          window.storyblok.on('enterEditmode', (payload: any) => {});
        }
      } catch (err) {
        console.log(err);
      }
    });
    import('src/app/storyblok/components').then((cp) => {
      this.components = cp.Components;
    });
    this.renderImmediately();
  }

  @HostListener('window:mousemove', ['$event'])
  handleMouseMove(): void {
    if (this.environmentService.isBrowser() && !this.hasRun) {
      this.hasRun = true;
      this.renderLazily();
    }
  }

  async ngOnInit() {
    (await this.storyBlokService.getTheme()).subscribe((theme) => {
      this.story = theme;
    });
  }

  renderImmediately() {
    let ahrefsMetaTag = this.renderer.createElement('meta');
    this.renderer.setAttribute(
      ahrefsMetaTag,
      'name',
      'ahrefs-site-verification'
    );
    this.renderer.setAttribute(
      ahrefsMetaTag,
      'content',
      '47cb6dde1bb8568d6a347238406f36bbb2db464f3510a3811de95f39f7644c9c'
    );
    this.renderer.appendChild(this._document.head, ahrefsMetaTag);
    this.renderer.setAttribute(
      this._document.documentElement,
      'lang',
      this.environmentService.environment.localeCode
    );

    let jsonld = this.renderer.createElement('script');
    jsonld.type = `application/ld+json`;
    jsonld.text = `
   {
    "@context": "http://schema.org",
    "@type": "WebSite",
    "url": "${this.environmentService.environment.siteUrl}",
    "name": "Made By Fressko",
    "description": "Stylish & sustainable. Shop the famous range of reusable coffee cups and tea infuser bottles from Made By Fressko. Keep your cup! Order yours online today.",
    "potentialAction": {
      "@type": "SearchAction",
      "target": "${this.environmentService.environment.siteUrl}/?s={search_term}",
      "query-input": "required name=search_term"
    }
  }
`;
    this.renderer.appendChild(this._document.head, jsonld);
  }

  renderLazily() {
    let klaviyo = this.renderer.createElement('script');
    klaviyo.async = true;
    klaviyo.defer = true;
    klaviyo.src =
      `https://static.klaviyo.com/onsite/js/${this.environmentService.environment.klaviyoId}/klaviyo.js?company_id=` +
      this.environmentService.environment.klaviyoId;

    this.renderer.appendChild(this._document.head, klaviyo);

    // Create and inject the oke:subscriber_id meta tag
    let klaviyoInit = this.renderer.createElement('script');
    klaviyoInit.innerHTML = `!(function () {
              if (!window.klaviyo) {
                window._klOnsite = window._klOnsite || [];
                try {
                  window.klaviyo = new Proxy(
                    {},
                    {
                      get: function (n, i) {
                        return "push" === i
                          ? function () {
                              var n;
                              (n = window._klOnsite).push.apply(n, arguments);
                            }
                          : function () {
                              for (
                                var n = arguments.length, o = new Array(n), w = 0;
                                w < n;
                                w++
                              )
                                o[w] = arguments[w];
                              var t =
                                  "function" == typeof o[o.length - 1]
                                    ? o.pop()
                                    : void 0,
                                e = new Promise(function (n) {
                                  window._klOnsite.push(
                                    [i].concat(o, [
                                      function (i) {
                                        t && t(i), n(i);
                                      },
                                    ])
                                  );
                                });
                              return e;
                            };
                      },
                    }
                  );
                } catch (n) {
                  (window.klaviyo = window.klaviyo || []),
                    (window.klaviyo.push = function () {
                      var n;
                      (n = window._klOnsite).push.apply(n, arguments);
                    });
                }
              }
            })();`;
    this.renderer.appendChild(this._document.head, klaviyoInit);

    // Create and inject the oke:subscriber_id meta tag
    let okeMetaTag = this.renderer.createElement('meta');
    this.renderer.setAttribute(okeMetaTag, 'name', 'oke:subscriber_id');
    this.renderer.setAttribute(
      okeMetaTag,
      'content',
      this.environmentService.environment.okendoSubscriberId
    );
    this.renderer.appendChild(this._document.head, okeMetaTag);

    let gtm = this.renderer.createElement('script');
    gtm.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${this.environmentService.environment.gtmId}');
`;
    this.renderer.appendChild(this._document.head, gtm);

    // Add noscript fallback
    let noscript = this.renderer.createElement('noscript');
    let iframe = this.renderer.createElement('iframe');
    iframe.src =
      'https://www.googletagmanager.com/ns.html?id=' +
      this.environmentService.environment.gtmId;
    iframe.height = '0';
    iframe.width = '0';
    iframe.style = 'display:none;visibility:hidden';
    this.renderer.appendChild(noscript, iframe);
    this.renderer.appendChild(this._document.body, noscript);

    let gtagOffsite = this.renderer.createElement('script');
    gtagOffsite.async = true;
    gtagOffsite.src =
      'https://www.googletagmanager.com/gtag/js?id=' +
      this.environmentService.environment.gtagId;
    this.renderer.appendChild(this._document.body, gtagOffsite);

    let gtag = this.renderer.createElement('script');

    gtag.innerHTML = `window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', '${this.environmentService.environment.gtagId}');`;
    this.renderer.appendChild(this._document.head, gtag);
  }
}
