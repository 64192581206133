import { Component, HostListener } from '@angular/core';
import { EnvironmentService } from 'src/app/service/environment.service';
import { ThemeService } from 'src/app/service/theme.service';

@Component({
    selector: 'app-store-selector-toggle',
    imports: [],
    templateUrl: './store-selector-toggle.component.html',
    styleUrl: './store-selector-toggle.component.scss'
})
export class StoreSelectorToggleComponent {
  environment: any;
  constructor(
    private themeService: ThemeService,
    private environmentService: EnvironmentService
  ) {
    this.environment = this.environmentService.environment;
  }

  @HostListener('click', ['$event'])
  onClick() {
    this.themeService.countryOpen();
  }
}
