import { ImageLoaderConfig } from '@angular/common';

export function imageHandler(config: ImageLoaderConfig) {
  if (!config.src) return '';
  if (config.src.includes('.svg')) {
    return config.src;
  }
  if (config.src.includes('shopify')) {
    const url = new URL(config.src);
    if (config.width) {
      url.searchParams.append('width', config.width.toFixed(0) || '0');
      if (config.loaderParams?.aspect) {
        url.searchParams.append(
          'height',
          (config.width / config.loaderParams.aspect).toFixed(0) || '0'
        );
        if (config.loaderParams.crop != false) {
          url.searchParams.append('crop', 'center');
        }
      }
    }
    return url.href;
  }
  return `${config.src}/m/${config.width || 0}x${
    config?.loaderParams?.aspect && config.width
      ? (config.width / config.loaderParams.aspect).toFixed(0) || '0'
      : 0
  }`;
}
