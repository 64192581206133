import { NgClass, NgFor, NgIf, NgOptimizedImage } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationSkipped,
  Router,
  RouterModule,
} from '@angular/router';
import { DynamicModule } from 'ng-dynamic-component';
import { FadeInOut } from 'src/app/animations/fade';
import { PreventDefaultDirective } from 'src/app/directive/prevent-default.directive';
import { StoryblokLinkDirective } from 'src/app/directive/storyblok-link.directive';
import { StoryblokDirective } from 'src/app/directive/storyblok.directive';
import { EnvironmentService } from 'src/app/service/environment.service';
import { ShopifyService } from 'src/app/service/shopify.service';
import { ThemeService } from 'src/app/service/theme.service';
import { MobileMenuCommandsComponent } from '../mobile-menu-commands/mobile-menu-commands.component';
import { StoreSelectorToggleComponent } from '../store-selector-toggle/store-selector-toggle.component';
import { SearchOverlayComponent } from './search-overlay/search-overlay.component';

@Component({
  selector: 'app-header',
  animations: [FadeInOut(300, 300, true)],
  imports: [
    NgIf,
    NgFor,
    NgClass,
    DynamicModule,
    NgOptimizedImage,
    MobileMenuCommandsComponent,
    StoryblokDirective,
    RouterModule,
    PreventDefaultDirective,
    StoryblokLinkDirective,
    SearchOverlayComponent,
    StoreSelectorToggleComponent,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnChanges {
  @Input() theme: any;
  @Input() showribbon: boolean = false;
  @Input() _editable!: string;
  @Input() _uid!: string;
  @Input() component!: string;

  components: any;

  _active: any = null;
  _activeMobile: boolean = false;
  _activeSearch: boolean = false;
  _isScrolled: boolean = false;
  private previousUrl: string | undefined;

  cart: any = [];
  wishlist: any;
  window;

  constructor(
    private eRef: ElementRef,
    private environmentService: EnvironmentService,
    private themeService: ThemeService,
    private renderer: Renderer2,
    private router: Router,
    private shopifyService: ShopifyService,
    private route: ActivatedRoute
  ) {
    this.window = this.environmentService.getWindow();
    import('src/app/storyblok/components').then((cp) => {
      this.components = cp.Components;
    });
    this.shopifyService.cartObservable.subscribe((data: any) => {
      if (data?.lines) {
        this.cart = data.lines?.edges || [];
      }
    });
    this.themeService.themeEvents.subscribe((data) => {
      if (data.type == 'clear' || data.type == 'menuClose') {
        this.close();
        this.theme.content.mobile_menu.forEach((x: any) => (x._active = false));
        this.renderer.removeClass(
          this.environmentService.getWindow()?.document.body,
          'overflow-hidden'
        );
      } else if (data.type == 'searchOpen') {
        this.close();
        this._activeSearch = true;
        setTimeout(() => {
          this.renderer.addClass(
            this.environmentService.getWindow()?.document.body,
            'overflow-hidden'
          );
        }, 30);
      } else if (data.type == 'minicartOpen') {
        this.close();
        setTimeout(() => {
          this.renderer.addClass(
            this.environmentService.getWindow()?.document.body,
            'overflow-hidden'
          );
        }, 30);
      } else if (data.type == 'menuOpen') {
        this.close();
        this._activeMobile = true;
        setTimeout(() => {
          this.renderer.addClass(
            this.environmentService.getWindow()?.document.body,
            'overflow-`1hidden'
          );
        }, 30);
      } else if (data.type != 'menuOpen') {
        this.close();
        this.onWindowScroll();
      }
    });
    this.router.events.subscribe((value: any) => {
      if (value instanceof NavigationSkipped) {
        this.close();
      }
      if (value instanceof NavigationEnd) {
        const currentUrl = value.url.split('?')[0];
        this.close();
        if (!this.previousUrl || this.previousUrl !== currentUrl) {
          setTimeout(() => {
            this.onWindowScroll();
            this.onResize();
          }, 60);
        }
        this.previousUrl = currentUrl;
      }
    });
    this.route.queryParams.subscribe((params) => {
      if (params.modal) {
        if (params.modal === 'search') {
          this.themeService.searchOpen();
        }
        if (params.modal === 'cart') {
          this.themeService.minicartOpen();
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      this.onWindowScroll();
      this.onResize();
    }, 60);
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event) {
    if (this.eRef.nativeElement.contains(event.target)) {
    } else {
      this.close();
    }
  }

  openMenu(blok: any) {
    this.closeSearch();
    this._active = blok;
  }

  toggleMobileMenu() {
    this.themeService.menuOpen();
  }

  toggleSearch() {
    this.themeService.searchOpen();
  }

  toggleMinicart() {
    this.themeService.minicartOpen();
  }

  close() {
    this.renderer.removeClass(
      this.environmentService.getWindow()?.document.body,
      'overflow-hidden'
    );

    this._active = null;
    this._activeMobile = false;
    this._activeSearch = false;
  }

  closeSearch() {
    this.renderer.removeClass(
      this.environmentService.getWindow()?.document.body,
      'overflow-hidden'
    );
    this._activeSearch = false;
  }

  @HostListener('window:resize', [])
  onResize() {
    // this.close();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (this.environmentService.isBrowser()) {
      const scrollTop =
        this.window?.pageYOffset ||
        this.window?.document.documentElement.scrollTop ||
        this.window?.document.body.scrollTop ||
        0;
      this._isScrolled = scrollTop > 0;
    }
  }
}
