import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { DynamicModule } from 'ng-dynamic-component';
import { StoryblokDirective } from 'src/app/directive/storyblok.directive';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
    selector: 'app-ribbon',
    imports: [CommonModule, DynamicModule, StoryblokDirective],
    animations: [
        trigger('fade', [
            state('void', style({
                opacity: 0,
            })),
            transition(':enter, :leave', [animate('.3s')]),
        ]),
    ],
    templateUrl: './ribbon.component.html',
    styleUrl: './ribbon.component.scss'
})
export class RibbonComponent {
  @Input() theme: any;

  @Input() _editable!: string;
  @Input() _uid!: string;
  @Input() component!: string;

  components: any;

  constructor() {
    import('src/app/storyblok/components').then((cp) => {
      this.components = cp.Components;
    });
  }
}
